<template>
  <div class="two-line-detail">
    <van-nav-bar left-arrow title="详情" @click-left="$router.back()" />
    <div :class="['list-wrapper', showApprove && 'show-button']">
      <div v-for="(item, index) in list" :key="item.id" class="item">
        <div class="title-wrapper">
          <span class="index">{{ index + 1 }}</span>
          <span class="title">工艺位号：{{ item.num || "--" }}</span>
        </div>
        <div class="intro">
          <div class="description">
            <span>位号描述：</span>
            <span>{{ item.name || "--" }}</span>
          </div>
          <div class="description">
            <span>监测设备设施：</span>
            <span>{{ item.poiName || "--" }}</span>
          </div>
        </div>
        <div class="other">
          <div class="left">
            <span class="label">监测项:</span>
            <span class="value">{{ item.indicatorTypeName || "--" }}</span>
          </div>
          <div class="right">
            <span class="label">参数单位:</span>
            <span class="value">{{ item.indicatorUnit || "--" }}</span>
          </div>
        </div>
        <div v-if="index !== list.length - 1" class="line-block" />
      </div>
    </div>
    <div v-if="showApprove" class="details-page__footer-button-wrapper--fixed">
      <van-button block round type="info" @click="onApprove">审批</van-button>
    </div>
  </div>
</template>

<script>
import {
  getAuthTwoApproveById,
  getInfoTwoLineById,
  getListTwoLineBitNum
} from "@/api/warning";
import { mapState } from "vuex";

export default {
  name: "TwoLineDetail",
  props: ["id"],
  data() {
    return {
      list: [],
      routerPath: "",
      auth: false,
      info: {
        approveStatus: ""
      }
    };
  },
  created() {
    const type = this.$route.meta?.type ?? 0;
    const routerMap = { 0: "/onoffline", 1: "/onoffline-process" };
    this.routerPath = routerMap[type] || "/onoffline";
    console.log(type, "type");
    this.getInfo();
    this.getList();
    this.getApproveAuth();
  },
  computed: {
    ...mapState({
      btnAuth: state => state.menu.btnAuth
    }),
    showApprove() {
      return (
        this.btnAuth.approve && +this.info.approveStatus === 1 && this.auth
      );
    }
  },
  methods: {
    async getInfo() {
      this.info = await getInfoTwoLineById(this.id);
    },
    async getApproveAuth() {
      this.auth = await getAuthTwoApproveById(this.id, this.userInfo.id);
    },
    getList() {
      const params = {
        orgCode: this.userInfo.orgCode,
        groupId: this.id,
        allStatus: 1,
        sort: "deleteStatus_asc"
      };
      getListTwoLineBitNum(params).then(res => {
        this.list = res ?? [];
      });
    },
    onApprove() {
      this.$router.push(`${this.routerPath}/${this.id}/approve`);
    }
  }
};
</script>

<style lang="scss">
.two-line-detail {
  .details-page__footer-button-wrapper--fixed {
    background-color: #f3f5f8;
    .van-button {
      box-shadow: none;
    }
  }
  .list-wrapper {
    height: calc(100vh - 58px);
    overflow-y: auto;
    background-color: #f3f5f8;
    color: #15171b;
    .item {
      padding-top: 20px;
      background-color: #fff;
      .line-block {
        background-color: #f3f5f8;
      }
      .title-wrapper {
        margin-bottom: 10px;
        .index {
          display: inline-block;
          width: 22px;
          height: 20px;
          padding-left: 6px;
          box-sizing: border-box;
          background: #1678ff;
          border-radius: 0 10px 10px 0;
          color: #fff;
          font-size: 12px;
          line-height: 22px;
          margin-right: 10px;
        }
        .title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC, serif;
          font-weight: 500;
          line-height: 22px;
        }
      }
      .intro,
      .other {
        padding: 0 16px;
        box-sizing: border-box;
        font-size: 12px;
      }
      .description {
        line-height: 28px;
        span:first-child {
          display: inline-block;
          width: 84px;
          margin-right: 11px;
          color: #5d5f63;
        }
      }
      .intro {
        margin-bottom: 6px;
      }
      .other {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        .label {
          font-family: PingFangSC-Regular, PingFang SC, serif;
          font-weight: 400;
          color: #a0a2a6;
          margin-right: 8px;
        }
      }
    }
  }
  .show-button {
    height: calc(100vh - 58px - 72px);
  }
}
</style>
